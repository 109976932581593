import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isBoolean } from "../utils/global"
import { Translations } from "../utils/translations"

const SsoRedirection = () => {
  const automaticallyAttemptSsoLogin = async () => {
    try {
      // Re-submitting the form on user's behalf
      const myForm = document.createElement("form")
      myForm.method = "post"
      myForm.action = "/api/ssoAuth"

      const myInput = document.createElement("input")
      myInput.setAttribute("name", "eventId")
      myInput.setAttribute("value", process.env.EVENT_ID)
      myForm.appendChild(myInput)
      myForm.style.display = "none"
      document.body.appendChild(myForm)
      myForm.submit()
    } catch (error) {
      navigate("/404")
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isBoolean(process.env.LOGIN_WITH_SSO)) automaticallyAttemptSsoLogin()
      else navigate("/404")
    }
  }, [])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "60vh",
        fontSize: "24px",
        width: "100%",
      }}
    >
      {Translations.ssoIssue.retrySso}
    </div>
  )
}

export default SsoRedirection
